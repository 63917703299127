import { Component, Output, EventEmitter, AfterViewChecked, AfterViewInit, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

import { AuthService } from 'src/app/services/auth.service';
import { EnvService } from './../../services/env.service';
import { environment as env } from 'src/environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
  @Output() themeChange: EventEmitter<string> = new EventEmitter<string>();

  isLogged: boolean;
  isLoggedSubs: Subscription;
  username: string;
  isWarned: boolean;
  logoUrl = 'assets/img/salescon-dark-theme.png';
  temas = [
    { nome: 'dark', descricao: 'Tema escuro' },
    { nome: 'light', descricao: 'Tema claro' }
  ];
  // Por enquanto vindo como default o tema dark. Mas tem que fazer a busca no localStorage
  temaAtual = 'dark';
  btnColor = '#201e1e';

  ambiente = {
    pre: '',
    tooltip: '',
    cor: ''
  };
  // Variável de controle do menu
  menu = [];
  fetchingMenu = false;

  constructor(
    public authService: AuthService,
    private router: Router,
    private environment: EnvService,
    private http: HttpClient,
  ) {
    const temaAplicacao = localStorage.getItem('temaAplicacao');
    if (temaAplicacao && temaAplicacao.length > 1) {
      this.temaAtual = temaAplicacao;
      this.setLoginBtnColor();
      this.logoUrl = `assets/img/salescon-${temaAplicacao}-theme.png`;
    }
  }

  ngOnInit() {
    this.isLoggedSubs = this.authService.isLogged$
      .subscribe(async (isLogged) => {
        this.isLogged = isLogged;
        if (!isLogged) {
          return;
        }

        this.username = this.authService.getUserAttibute('name');
        const valid =this.authService.checkValidADUser()
        if (valid) {
          this.setConfiguracaoMenu();
        }
      });
  }

  ngOnDestroy(): void {
    this.isLoggedSubs.unsubscribe();
  }

  ngAfterViewInit() {
    this._handleNavigation();
  }

  ngAfterViewChecked(): void {
    this._handleNavigation();
  }

  /**
   * Tratamento para navegação
   */
  private _handleNavigation(): void {
    if (this.menu.length > 0) {
      const url = window.location.href;
      if (url.includes('/admin/')) {
        this.change('administracao');
      } else if (url.includes('relatorio')) {
        this.change('relatorios');
      }
    }
  }

  /**
   * Alteração de estado dos botões
   * @param name
   */
  private change(name: string): void {
    const buttons = document.querySelectorAll('.button-header');
    const buttonClicked = document.getElementById(name);

    buttons.forEach(button => {
      if (button?.classList?.contains('selected')) {
        button.classList.remove('selected');
      }
    });
    buttonClicked.classList.add('selected');
  }

  /**
   * Inicia proceso de autenticação
   */
  login(){
    this.authService.openMSLogin();
  }

  /**
   * Navegação para home da aplicação
   */
  goHome(): void {
    this.router.navigate(['home']);
  }

  /**
   * Carregamento do badge do ambiente
   */
  loadBadgeAmbiente() {
    const amb = this.environment.getEnvId();
    this.ambiente.pre = amb.pre;
    this.ambiente.tooltip = amb.tooltp;
    this.ambiente.cor = amb.cor;
  }

  /**
   * Tratamento para tema da aplicação
   * @param tema
   */
  themeHandler(tema: any) {
    if (this.temaAtual !== tema) {
      this.logoUrl = `assets/img/salescon-${tema}-theme.png`;
      this.temaAtual = tema;
      this.themeChange.emit(tema);
    }
  }

  /**
   * Define a configuração do menu
   */
  private async setConfiguracaoMenu(): Promise<void> {
    try {
      this.fetchingMenu = true;
      const configMenu = await this.http.get<any>(`${env.API_ENDPOINT}/api/v3/menu`).toPromise();
      this.menu = configMenu.menu;
    } catch (error) {
      this.menu = [];
    } finally {
      this.fetchingMenu = false;
    }
  }

  /**
   * Método responsável para controlar as condições do menu
   * @param condicao id do item do menu
   * @returns boolean
   */
  visualizarMenu(condicao: string): boolean {
    switch (condicao) {
      case 'monitor':
        return true;
      case 'administracao':
        return true;
      default:
        return true;
    }
  }

  /**
   * Método responsável para controlar as condições dos sub-menus
   * @param condicao id do item do sub-menu
   * @returns boolean
   */
  visualizarSubMenu(condicao: string): boolean {
    switch (condicao) {
      default:
        return true;
    }
  }

  setLoginBtnColor(){
    if (this.temaAtual === 'light') {
      this.btnColor = '#e6e6e6';
    }
  }
}
