import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';

import { HomeComponent } from 'src/app/components/home/home.component';
import { PrincipalComponent } from 'src/app/components/principal/principal.component';
import { RepresentanteComponent } from 'src/app/components/admin/representante/representante.component';
import { LogoutComponent } from './components/logout/logout.component';
import { MonitorPreCadastroComponent } from './components/admin/monitor-pre-cadastro/monitor-pre-cadastro.component';
import { ProdutoImagemComponent } from './components/admin/produto-imagem/produto-imagem.component';
import { LoginComponent } from './components/login/login.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'home', component: HomeComponent, canActivate: [MsalGuard] },
  { path: 'admin/representante', component: RepresentanteComponent, canActivate: [MsalGuard] },
  { path: 'admin/pre-cadastro', component: MonitorPreCadastroComponent, canActivate: [MsalGuard] },
  { path: 'admin/produto-imagem', component: ProdutoImagemComponent, canActivate: [MsalGuard] },
  { path: 'logout', component: LogoutComponent, canActivate: [MsalGuard] },
  { path: 'principal', component: PrincipalComponent, canActivate: [MsalGuard] },
  {
    // Needed for hash routing
    path: 'code',
    component: LoginComponent
  },
  { path: '**', component: LoginComponent }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    // Don't perform initial navigation in iframes
    initialNavigation: !isIframe ? 'enabledBlocking' : 'disabled',
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
