import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

import { environment as env } from 'src/environments/environment';
import { ConfigService } from 'src/app/services/config.service'
import { LoaderService } from 'src/app/services/loader.service';
import { NovoUsuarioDialogComponent } from 'src/app/dialogs/novo-usuario-dialog/novo-usuario-dialog.component';
import { AlterarSenhaDialogComponent } from 'src/app/dialogs/alterar-senha-dialog/alterar-senha-dialog.component';
import { ExcluirUsuarioDialogComponent } from 'src/app/dialogs/excluir-usuario-dialog/excluir-usuario-dialog.component';
import { ConfirmacaoExclusaoDialogComponent } from 'src/app/dialogs/confirmacao-exclusao-dialog/confirmacao-exclusao-dialog.component';

export interface Representante {
  representanteId: string;
  nome: string;
  usuario: string;
  _id: string;
  _rev: string;
}

@Injectable({
  providedIn: 'root'
})
export class RepresentanteService {
  private mainUrl = `${env.API_ENDPOINT}/api/v3/representantes`;

  constructor(
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private config: ConfigService,
    private loader: LoaderService,
  ) { }

  /**
   * Recupera lista de representantes
   */
  public getRepresentantes(): Observable<Representante[]> {
    return this.httpClient.get<Representante[]>(`${this.mainUrl}`);
  }

  /**
   * Criação de usuário
   * @param representante
   * @returns
   */
  public async criarUsuario(representante: Representante): Promise<boolean> {
    const appConfig = await this.config.getConfig();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      usuario: '',
      dominio: appConfig.dominio,
      senha: ''
    };
    dialogConfig.minWidth = '30%';

    const dialogReturn = await this.dialog.open(NovoUsuarioDialogComponent, dialogConfig).afterClosed().toPromise();
    if (dialogReturn) {
      this.loader.show();
      try {
        const request = {
          representante: {
            representanteId: representante.representanteId,
            nome: representante.nome,
            _id: representante._id,
          },
          usuario: dialogReturn
        };

        await this.httpClient.post(`${this.mainUrl}`, request).toPromise();
        this.toastr.success('Usuário criado com sucesso!!!');
        return true;
      }
      catch (error) {
        console.error(error);
        if (error.error && error.error.message) {
          this.toastr.error(error.error.message);
        }
        else {
          this.toastr.error('Erro ao criar o usuário!!!');
        }
      }
      finally {
        this.loader.hide();
      }
    }
    return false;
  }

  /**
   * Exclusão de usuário
   * @param representante
   * @returns
   */
  public async excluirUsuario(representante: Representante): Promise<boolean> {
    const appConfig = await this.config.getConfig();

    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      usuario: representante.usuario,
      senha: ''
    };
    dialogConfig.minWidth = '30%';

    const dialogReturn = await this.dialog.open(ExcluirUsuarioDialogComponent, dialogConfig).afterClosed().toPromise();
    if (!dialogReturn) {
      return false;
    }

    const confirmacaoDialogConfig = new MatDialogConfig();
    confirmacaoDialogConfig.data = {
      usuario: representante.usuario,
      delecaoUsuario: appConfig.delecaoUsuario,
    };
    confirmacaoDialogConfig.minWidth = '30%';
    const dialogConfirmationReturn = await this.dialog.open(ConfirmacaoExclusaoDialogComponent, confirmacaoDialogConfig)
      .afterClosed()
      .toPromise();
    if (!dialogConfirmationReturn) {
      return false;
    }

    this.loader.show();
    try {
      await this.httpClient.delete(`${this.mainUrl}/${representante.usuario}`).toPromise();
      this.toastr.success('Usuário excluído com sucesso!!!');
      return true;
    } catch (error) {
      console.error(error);
      if (error?.error?.message) {
        this.toastr.error(error.error.message);
      } else {
        this.toastr.error('Erro ao excluir o usuário!!!');
      }
      return false;
    } finally {
      this.loader.hide();
    }
  }

  /**
   * Alteração de senha de usuário
   * @param representante
   */
  public async alterarSenha(representante: Representante): Promise<any> {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.data = {
      usuario: representante.usuario,
      senha: ''
    };
    dialogConfig.minWidth = '30%';

    const dialogReturn = await this.dialog.open(AlterarSenhaDialogComponent, dialogConfig).afterClosed().toPromise();
    if (dialogReturn) {
      this.loader.show();
      try {
        const request = {
          usuario: dialogReturn.usuario,
          senha: dialogReturn.senha,
          alterarSenhaPrimeiroLogon: dialogReturn.alterarSenhaPrimeiroLogon
        };
        await this.httpClient.post(`${this.mainUrl}/senha`, request).toPromise();
        this.toastr.success('Senha alterada com sucesso!!!');
      }
      catch (error) {
        if (error.error.message && error.error.code !== 'INTERNAL_ERROR') {
          console.error(error);
          this.toastr.error(error.error.message);
        } else {
          console.error(error);
          this.toastr.error('Erro ao alterar a senha!!!');
        }
      }
      finally {
        this.loader.hide();
      }
    }
  }
}
