import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { MatTableDataSource } from '@angular/material/table';

import { EnvService } from 'src/app/services/env.service';
import { LoaderService } from 'src/app/services/loader.service';
import { ConfigService } from 'src/app/services/config.service';
import { RepresentanteService, Representante } from 'src/app/services/representante.service';
import { Subject, Subscription } from 'rxjs';

@Component({
  selector: 'app-representante',
  templateUrl: './representante.component.html',
  styleUrls: ['./representante.component.scss']
})
export class RepresentanteComponent implements OnInit, OnDestroy {

  public displayedColumns: string[] = ['RepresentanteId', 'Nome', 'Usuario', 'Acoes'];

  public pesquisaFld: String;
  public logins = {
    adquiridos: 0,
    utilizados: 0,
    disponiveis: 0
  };
  public permissaoCriarUsuario = false;
  public dataSource = new MatTableDataSource();

  public filteredRepresentantes: any;
  public representantes: any;
  public filter$: Subject<string> = new Subject<string>();

  public subscribes: Subscription = new Subscription();

  constructor(
    public loader: LoaderService,
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
    private representanteService: RepresentanteService,
    private config: ConfigService) {
    iconRegistry.addSvgIcon('search',
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl('assets/img/search.svg')));
    iconRegistry.addSvgIcon('person-add',
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl('assets/img/person-add.svg')));
    iconRegistry.addSvgIcon('person-remove',
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl('assets/img/person-remove.svg')));
    iconRegistry.addSvgIcon('lock',
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl('assets/img/lock.svg')));
  }

  ngOnInit(): void {
    this._atualizarLista();

    // Filter pipe - debounce 1s
    const subFilter = this.filter$.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this._applyFilter(value);
      });

    // Adiciona o subscribe de filtro aos subs
    this.subscribes.add(subFilter);
  }

  ngOnDestroy(): void {
    this.subscribes.unsubscribe();
  }

  async setAdquiridos(): Promise<void> {
    await this.config.getConfig().then((data) => this.logins.adquiridos = data.loginsAdquiridos);
  }

  async handleQuantidadeLogins(representantes) {
    await this.setAdquiridos();
    const repCount = representantes.filter((rep) => rep.usuario);
    this.logins.utilizados = repCount.length;
    this.logins.disponiveis = this.logins.adquiridos - this.logins.utilizados;
    if (this.logins.disponiveis > 0) {
      this.permissaoCriarUsuario = true;
    }
  }

  /**
   * Aplica o filtro de acordo com o valor inserido pelo usuário
   * @param filterValue filtro
   */
  private _applyFilter(filterValue: string) {
    this.pesquisaFld = filterValue?.toLowerCase();
    this._filtrar();
  }

  /**
   * Método de filtro
   */
  private _filtrar() {
    // Colunas utilizadas para filtrar
    const columns = ['representanteId', 'nome', 'usuario'];
    // Função que realiza o filtro
    let filterFunc = (v) => {
      const filters = {};
      columns.forEach(column => {
        filters[column] = v[column] && v[column].toLowerCase().indexOf(this.pesquisaFld) >= 0;
      });
      // Verifica se alguma propriedade é true
      return Object.keys(filters).some((col) => filters[col]);
    };

    if (this.pesquisaFld) {
      this.filteredRepresentantes = this.representantes.filter(filterFunc);
    } else {
      this.filteredRepresentantes = JSON.parse(JSON.stringify(this.representantes));
    }

    // Reinsere os dados no dataSource
    this.dataSource.data = JSON.parse(JSON.stringify(this.filteredRepresentantes));
  }

  private _atualizarLista(): void {
    this.loader.show();
    this.representanteService.getRepresentantes().subscribe({
      next: reps => {
        this.representantes = reps;
        this.filteredRepresentantes = JSON.parse(JSON.stringify(reps));
        this.dataSource.data = reps;
        this.handleQuantidadeLogins(reps);
        this.loader.hide();
      },
      error: () => {
        this.loader.hide();
      }
    });
  }

  public async criarUsuario(representante: Representante) {
    const usuarioCriado = await this.representanteService.criarUsuario(representante);
    if (usuarioCriado) {
      this._atualizarLista();
    }
  }

  public async excluirUsuario(representante: Representante) {
    const excluido = await this.representanteService.excluirUsuario(representante);
    if (excluido) {
      this._atualizarLista();
    }
  }

  public async alterarSenha(representante: Representante) {
    await this.representanteService.alterarSenha(representante);
  }
}
