import { Component, OnInit, ViewChild, DoCheck } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { PreCadastroService } from 'src/app/services/pre-cadastro.service';
import { FormControl, Validators } from '@angular/forms';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { PreCadastroDataSource } from './../../../services/pre-cadastro.datasource';
import { debounceTime, distinctUntilChanged, tap, catchError } from 'rxjs/operators';
import { PreCadastro } from './../../../models/pre-cadastro';
import { EnvService } from 'src/app/services/env.service';
interface AreaAtuacao {
  areaAtuacaoId: string;
  descricao: string;
  _id: string;
  _rev: string;
}

@Component({
  selector: 'app-monitor-pre-cadastro',
  templateUrl: './monitor-pre-cadastro.component.html',
  styleUrls: ['./monitor-pre-cadastro.component.scss']
})
export class MonitorPreCadastroComponent implements OnInit, DoCheck {

  areasAtuacao: any;
  areaAtuacao: any;
  situacaoPreCadastro: any;
  areasAtuacaoControl = new FormControl('', Validators.required);
  inputControl = new FormControl('');
  preCadastroCount = 0;
  searchString: string;
  pageSize = 30;
  private bindPaginator = false;
  public displayedColumns: string[] = ['RepresentanteId', 'Tipo', 'CpfCnpj', 'Nome', 'Situacao'];

  dataSource: PreCadastroDataSource;
  input$ = this.inputControl.valueChanges
    .pipe(
      debounceTime(500),
      distinctUntilChanged()
    );

  @ViewChild(MatPaginator) paginator: MatPaginator;

  // MatPaginator Output
  pageEvent: PageEvent;

  constructor(
    private preCadastro: PreCadastroService,
    public loader: LoaderService,
    private iconRegistry: MatIconRegistry,
    private toastr: ToastrService,
    private sanitizer: DomSanitizer) {
    iconRegistry.addSvgIcon('search',
      sanitizer.bypassSecurityTrustResourceUrl(EnvService.assetsUrl('assets/img/search.svg')));
  }

  ngOnInit(): void {
    this.getAreasAtuacao();
    this.dataSource = new PreCadastroDataSource(this.preCadastro);
    this.input$
      .subscribe((searchString: string) => {
        this.searchString = searchString;
        this.paginator.pageIndex = 0;
        this.dataSource.loadPreCadastro(this.areaAtuacao, searchString, 'asc', 0, this.pageSize);
      });
    this.dataSource.loading$
      .subscribe(status => {
        status ? this.loader.show() : this.loader.hide();
      });
    this.dataSource.preCadastroCount$
      .subscribe(count => this.preCadastroCount = count);
    this.dataSource.error$
      .subscribe(err => {
        if (err) {
          this.toastr.error(err.message);
        }
      });
  }

  ngDoCheck() {
    if (this.paginator && !this.bindPaginator) {
      this.bindPaginator = true;
      // Inscrever nas alterações de página
      this.paginator.page
        .pipe(
          tap(() => this.loadPreCadastroPage())
        )
        .subscribe();
    }
  }

  loadPreCadastroPage() {
    this.dataSource.loadPreCadastro(
      this.areaAtuacao,
      this.searchString,
      'asc',
      this.paginator.pageIndex,
      this.paginator.pageSize,
    );
  }

  getAreasAtuacao(): void {
    this.loader.show();
    this.preCadastro.getAreasAtuacao().subscribe({
      next: areasAtuacaoRet => {
        this.areasAtuacao = areasAtuacaoRet;
        this.loader.hide();
      },
      error: (error) => {
        this.loader.hide();
        console.log(error);
        this.toastr.error(error.error.message);
      }
    });
  }

  onSelectAreaAtuacao(value: AreaAtuacao) {
    this.updateAreaAtuacao(value);
    this.inputControl.setValue('', { emitEvent: false });
    if (this.paginator) {
      // quando alterar área de atuação, retornar para primeira página
      this.paginator.pageIndex = 0;
    }
    this.loader.show();
    this.dataSource.loadPreCadastro(value.areaAtuacaoId, '', 'asc', 0, this.pageSize);
  }

  updateAreaAtuacao(value?: AreaAtuacao) {
    if (value) {
      this.areaAtuacao = value.areaAtuacaoId;
    } else {
      this.areaAtuacao = this.areaAtuacao ? this.areaAtuacao : undefined;
    }
  }

  async showDetails(dataPicked: PreCadastro) {
    const dataUpdated = await this.preCadastro.showDetails(dataPicked, this.areaAtuacao);
    if (dataUpdated) {
      this.loadPreCadastroPage();
    }
  }

  mascaraCNPJ(cnpj) {
    return cnpj.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '\$1.\$2.\$3\/\$4\-\$5');
  }

  mascaraCPF(cpf) {
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
  }
}
